import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from '../../../../common/wrappers/ReactRouterDom';
import UseForm from "../../../../common/utils/UseForm";
import { formatTimeForFilterObject } from '../../../../common/utils/TimesUtils';

import useTimeValidationData from '../../../state/timeValidation/UseTimeValidationData';

import NavLinks from '../NavLinks';
import validate from './TimesValidationDetailValidation';

const useMeetValidationDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { putUpdateInvalidTime } = useTimeValidationData();

  const { formState, errorState, setFormData, handleSubmit, onFormValueChanged, setIsDirty
  } = UseForm(getInitialFormState(), submitFormCallback, validate);

  function submitFormCallback(formState) {
    const invalidTimeObj = { 
      swimTimeRecognitionValidationId: location.state?.invalidTime.swimTimeRecognitionValidationId,
      memberId: formState.memberId,
      swimTime: formatTimeForFilterObject(formState.time),
      lscCodeFromFile: formState.lscCode,
      clubCodeFromFile: formState.clubCode
    };

    const putUpdateInvalidTimePromise = putUpdateInvalidTime(location.state?.invalidTime.swimTimeRecognitionValidationId, invalidTimeObj);

      if (putUpdateInvalidTimePromise !== null) {
        putUpdateInvalidTimePromise.then((newState) => {
          if (newState !== null) {
            navigate(NavLinks.MEET_TIMES_VALIDATION);
          }
        }).catch((e) => {
          //setContextStateError(true);
        });
      }
  };

  const onBackButtonClicked = () => {
    navigate(NavLinks.MEET_TIMES_VALIDATION);
  };

  useEffect(() => {
    if (location.state) {
      setFormData({
        ...formState,
        memberId: location.state?.invalidTime.memberId || '',
        time: location.state?.invalidTime.swimTime || '',
        lscCode: location.state?.invalidTime.lscCodeFromFile || '',
        clubCode: location.state?.invalidTime.clubCodeFromFile || '',
        isRelay: location.state?.invalidTime.eventCode.includes('-R')
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  function getInitialFormState() {
    return ({
      memberId: '',
      time: '',
      lscCode: '',
      clubCode: '',
      isRelay: false
    })
  };

  return {
    formState,
    errorState,
    handleSubmit,
    setFormData,
    onFormValueChanged,
    onBackButtonClicked
  }
}

export default useMeetValidationDetail;