export const localValidate = (formState) => {
  let errors = {};

  if (formState.isRelay === false) {
    if (formState.memberId.trim().length === 0) {
      errors.memberId = 'Please select a member'
    } else if (formState.memberId.trim().length !== 14) {
      errors.memberId = 'Member ID must be 14 characters long'
    }
  }

  if (formState.time.trim().length === 0) {
    errors.time = 'Time is required'
  }

  return errors;
};

const TimesValidationDetailValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default TimesValidationDetailValidation;