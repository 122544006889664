import { useEffect } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import useMeetData from '../../../../common/state/meet/UseMeetData';
import NavLinks from '../NavLinks';

import useTimeValidationData from '../../../state/timeValidation/UseTimeValidationData';

const useTimesValidation = () => {
  const navigate = useNavigate();
  const { meetState } = useMeetData();
  const { timeValidationState, getTimeValidations, putTimeValidations } = useTimeValidationData();

  const onEditTime = (e, invalidTime) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.MEET_TIMES_VALIDATION_DETAIL, {
      state: {
        invalidTime: invalidTime
      }
    });
  }

  const onGetValidations = () => {
    const getTimeValidationsPromise = getTimeValidations(meetState.objData.meetId);

      if (getTimeValidationsPromise !== null) {
        getTimeValidationsPromise.then((newState) => {
          if (newState !== null) {
            // Do something with the newState
          }
        }).catch((e) => {
          //setContextStateError(true);
        });
      }
  }

  const onProcessValidations = () => {
    const putTimeValidationsPromise = putTimeValidations(meetState.objData.meetId);

      if (putTimeValidationsPromise !== null) {
        putTimeValidationsPromise.then((newState) => {
          if (newState !== null) {
            // Do something with the newState
          }
        }).catch((e) => {
          setContextStateError(true);
        });
      }
  }

  useEffect(() => {
      const getTimeValidationsPromise = getTimeValidations(meetState.objData.meetId);

      if (getTimeValidationsPromise !== null) {
        getTimeValidationsPromise.then((newState) => {
          if (newState !== null) {
            // Do something with the newState
          }
        }).catch((e) => {
          setContextStateError(true);
        });
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { 
    meetState, 
    timeValidationState, 
    onEditTime, 
    onGetValidations, 
    onProcessValidations 
  };
}

export default useTimesValidation;