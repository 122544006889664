
import { Fragment } from 'react';
import useTimesValidation from './UseTimesValidation';
import Headings from '../../../../common/components/headings/Headings';
import TimesValidationGrid from './TimesValidationGrid';  
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Constants from '../../../../common/utils/Constants';

const TimesValidation = () => {
  const { 
    meetState, 
    timeValidationState, 
    onEditTime, 
    onProcessValidations
  } = useTimesValidation();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Times Validation for - {meetState.objData.meetName}</Headings.H3>
        </div>
      </div>
      <div className="row usas-extra-bottom-margin">
        <div className='col-xs-12'>
          <TimesValidationGrid
            gridData={timeValidationState.arrayData}  
            isLoading={false}
            onEditTime={onEditTime}
          />
        </div>
      </div>
      <div className="row usas-extra-bottom-margin">
        <div className='col-xs-12'>
          <SecondaryButton onClick={onProcessValidations}>Re-process Validations</SecondaryButton>
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={timeValidationState.isArrayLoading} />
    </Fragment>);
}

export default TimesValidation; 