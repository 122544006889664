import { isValidDate } from '../../../../utils/validation';
import shared from './Shared';

const SESSION_FINAL = shared.SESSION_FINAL;
const SESSION_PRELIM = shared.SESSION_PRELIM;
const SESSION_SWIMOFF = shared.SESSION_SWIMOFF;

const TIMES_RECON = "R";

const SESSION_FINAL_LABEL = shared.SESSION_FINAL_LABEL;

const TIME_TYPE_INDIVIDUAL = shared.TIME_TYPE_INDIVIDUAL;
const TIME_TYPE_RELAY = shared.TIME_TYPE_RELAY;

const SDIF_COURSE_SCY = 'Y';
const SDIF_COURSE_SCM = 'S';
const SDIF_COURSE_LCM = 'L';
const COURSE_SCY_JSON_OUTPUT = 'SCY';
const COURSE_SCM_JSON_OUTPUT = 'SCM';
const COURSE_LCM_JSON_OUTPUT = 'LCM';
const SDIF_TEAM_ID_RECORD = 'C1';
// const SDIF_TEAM_ENTRY_RECORD = 'C2';
const SDIF_INDIVIDUAL_EVENT_RECORD = 'D0';
const SDIF_RELAY_EVENT_RECORD = 'E0';
const SDIF_RELAY_NAME_RECORD = 'F0';
const SDIF_SPLITS_RECORD = 'G0'

const ERROR_TYPE_STRUCTURE = "Structure";
const ERROR_TYPE_DATA = "Data";

//person and times
const D0ColMap = {
  'name': { start: 11, end: 39 },
  'gender': { start: 65, end: 66 },
  'seedTime': { start: 88, end: 96 },
  'swimTimeSwimOff': { start: 106, end: 114 },
  'swimTimeFinal': { start: 115, end: 123 },
  'swimTimePrelim': { start: 97, end: 105 },
  'courseCodeFinal': { start: 123, end: 124 },
  'courseCodePrelim': { start: 105, end: 106 },
  'courseCodeSwimOff': { start: 114, end: 115 },
  'courseCodeSeed': { start: 96 , end: 97 },
  'birthDate': { start: 55, end: 63 },
  'swimDate': { start: 80, end: 88 },
  'distance': { start: 67, end: 71 },
  'strokeId': { start: 71, end: 72 },
  'heatNumberPrelim': { start: 124, end: 126 },
  'heatNumberFinal': { start: 128, end: 130 },
  'laneNumberPrelim': { start: 126, end: 128 },
  'laneNumberFinal': { start: 130, end: 132 },
  'placeRankingPrelim': { start: 132, end: 135 },
  'placeRankingFinal': { start: 135, end: 138 }
}

const D3ColMap = {
  'memberId': { start: 2, end: 16}
}

//splits
const G0ColMap = {
  'splitsLine': { start: 63, end: 143 },
  'splitsSession': { start: 143, end: 144 }
}

//splits by posistion
const G0SplitPosColMap = {
  0: { start: 0, end: 8 },
  1: { start: 8, end: 16 },
  2: { start: 16, end: 24 },
  3: { start: 24, end: 32 },
  4: { start: 32, end: 40 },
  5: { start: 40, end: 48 },
  6: { start: 48, end: 56 },
  7: { start: 56, end: 64 },
  8: { start: 64, end: 72 },
  9: { start: 72, end: 80 },
}

//club
const C1ColMap = {
  'lscId': { start: 11, end: 13 },
  'clubCode': { start: 13, end: 17 },
  'clubName': { start: 17, end: 47 }
}

//relay
const E0ColMap = {
  'eventGender': { start: 20, end: 21 },
  'distance': { start: 21, end: 25 },
  'swimDate': { start: 37, end: 45 },
  'seedTime': { start: 45, end: 53 },
  'timePrelim': { start: 54, end: 62 },
  'timeFinal': { start: 72, end: 80 },
  'strokeId': { start: 25, end: 26 },
  'courseCodePrelim': { start: 62, end: 63 },
  'courseCodeFinal': { start: 80, end: 81 },
  'courseCodeSeed': { start: 53 , end: 54 },
  'heatNumberPrelim': { start: 81, end: 83 },
  'heatNumberFinal': { start: 85, end: 87 },
  'laneNumberPrelim': { start: 83, end: 85 },
  'laneNumberFinal': { start: 87, end: 89 },
  'placeRankingPrelim': { start: 89, end: 92 },
  'placeRankingFinal': { start: 92, end: 95 }
}

//relay leg
const F0ColMap = {
  'name': { start: 22, end: 49 },
  'birthDate': { start: 65, end: 73 },
  'gender': { start: 75, end: 76 },
  'legTime': { start: 79, end: 87 },
  'legNumberPrelim': { start: 76, end: 77 },
  'legNumberSwimoff': { start: 77, end: 78 },
  'legNumberFinal': { start: 78, end: 79 },
  'memberId': { start: 92, end: 106 }
}

const sessionCodeMap = {
  'F': { value: 'Final' },
  'P': { value: 'Prelim' },
  'S': { value: 'SwimOff' },
  'R': { value: 'Seed' }
}

function logError(errorArray, errorType, errorMessage, lineNumber) {
  const errorObj = {
    errorType: errorType,
    errorMessage: errorMessage,
    lineNumber: lineNumber
  }

  errorArray.push(errorObj);
}

function errorCheckClubObject(clubObj, fileRecordNumber, errorArray) {
  let errorFound = false;

  if (clubObj.clubCode.length === 0) {
    logError(errorArray, ERROR_TYPE_DATA, 'Club Code is required', fileRecordNumber);
    errorFound = true;
  }

  return errorFound;
}

function errorCheckPersonObject(personObj, fileRecordNumber, errorArray) {
  let errorFound = false;

  if (personObj.lastName.length === 0) {
    logError(errorArray, ERROR_TYPE_DATA, 'Last Name is required', fileRecordNumber);
    errorFound = true;
  }

  if (personObj.firstName.length === 0) {
    logError(errorArray, ERROR_TYPE_DATA, 'First Name is required', fileRecordNumber);
    errorFound = true;
  }

  if (!isValidDate(personObj.birthDate)) {
    logError(errorArray, ERROR_TYPE_DATA, 'A valid Birth Date is required', fileRecordNumber);
    errorFound = true;
  }

  return errorFound;
}

function errorCheckTimeObject(timeObj, fileRecordNumber, errorArray, session, timeType) {
  //skip error check for diving results
  if (timeObj.stroke === "H") {
    return false;
  }

  const clubErrorFound = errorCheckClubObject(timeObj.club, fileRecordNumber, errorArray);

  let timesErrorFound = false;

  if (timeObj.courseCode !== COURSE_SCY_JSON_OUTPUT &&
    timeObj.courseCode !== COURSE_LCM_JSON_OUTPUT &&
    timeObj.courseCode !== COURSE_SCM_JSON_OUTPUT) {
    logError(errorArray, ERROR_TYPE_DATA, `Course Code is not valid for ${session}s session`, fileRecordNumber);
    timesErrorFound = true;
  }

  if (!isValidDate(timeObj.swimDate)) {
    logError(errorArray, ERROR_TYPE_DATA, `Swim Date is not valid for ${session}s session`, fileRecordNumber);
    timesErrorFound = true;
  }

  const yardDists = ["25", "50", "100", "200", "400", "500", "800", "1000", "1650", "2000", "3000", "4000", "5000"];
  const meterDists = ["25", "50", "100", "200", "400", "800", "1500", "2000", "3000", "4000", "5000"];
  const dists = timeObj.courseCode === 'SCY' ? yardDists : meterDists;

  if (!dists.includes(timeObj.distance)) {
    logError(errorArray, ERROR_TYPE_DATA, `Swim Distance (${timeObj.distance}) is not valid for ${session}s session`, fileRecordNumber);
    timesErrorFound = true;
  }

  const individualStrokes = ["FR", "BK", "BR", "FL", "IM"];
  const relayStrokes = ["FR-R", "MED-R"];
  const strokeCodes = timeType === TIME_TYPE_INDIVIDUAL ? individualStrokes : relayStrokes;

  if (!strokeCodes.includes(timeObj.stroke)) {
    logError(errorArray, ERROR_TYPE_DATA, `Stroke Code is not valid for ${session}s session`, fileRecordNumber);
    timesErrorFound = true;
  }

  return clubErrorFound || timesErrorFound;
}

function checkStructureErrors(fileArray, errorArray, fileArrayIndex) {
  let recTypeCode = fileArray[fileArrayIndex].substring(0, 2);

  if (recTypeCode !== "A0") {
    logError(errorArray, 'structural', 'File Description record (A0) is missing', fileArrayIndex + 1);

    return false;
  }

  fileArrayIndex++;
  recTypeCode = fileArray[fileArrayIndex].substring(0, 2);

  if (recTypeCode !== "B1") {
    logError(errorArray, 'structural', 'Meet record (B1) is missing', fileArrayIndex + 1);

    return false;
  }

  fileArrayIndex++;
  recTypeCode = fileArray[fileArrayIndex].substring(0, 2);

  if (recTypeCode === "B2") {
    fileArrayIndex++;
    recTypeCode = fileArray[fileArrayIndex].substring(0, 2);
  }

  if (recTypeCode !== SDIF_TEAM_ID_RECORD) {
    logError(errorArray, 'structural', 'Club record (C1) is missing', fileArrayIndex + 1);

    return false;
  }

  //some files end up with empty element at the end of the array
  if (fileArray[fileArray.length - 1].trim().length === 0) {
    recTypeCode = fileArray[fileArray.length - 2].substring(0, 2);
  } else {
    recTypeCode = fileArray[fileArray.length - 1].substring(0, 2);
  }

  if (recTypeCode !== "Z0") {
    logError(errorArray, 'structural', 'Z0 record missing', fileArrayIndex + 1);

    return false;
  }

  return true;
}

const checkFileStructure = (fileArray, errorArray) => {
  let fileArrayIndex = 0;

  if (checkStructureErrors(fileArray, errorArray, fileArrayIndex) === false) {
    return false;
  }

  fileArrayIndex = 3;

  return true;
}

function getValSubstring(line, start, end) {
  return line.substring(start, end);
}

function getVal(line, colMap, val) {
  const cols = colMap[val];
  return line.substring(cols.start, cols.end).trim();
}

function getTimeAndCourseBySession(line, sessionCode) {
  const courseCode = getVal(line, D0ColMap, `courseCode${sessionCodeMap[sessionCode].value}`); 
  const swimTime = sessionCode === TIMES_RECON ? "" : getVal(line, D0ColMap, `swimTime${sessionCodeMap[sessionCode].value}`).trim();

  const heatNumber = (sessionCode === SESSION_FINAL || sessionCode === SESSION_SWIMOFF) ? getVal(line, D0ColMap, 'heatNumberFinal') : getVal(line, D0ColMap, 'heatNumberPrelim');
  const laneNumber = (sessionCode === SESSION_FINAL || sessionCode === SESSION_SWIMOFF) ? getVal(line, D0ColMap, 'laneNumberFinal') : getVal(line, D0ColMap, 'laneNumberPrelim');
  const placeRanking = (sessionCode === SESSION_FINAL || sessionCode === SESSION_SWIMOFF) ? getVal(line, D0ColMap, 'placeRankingFinal') : getVal(line, D0ColMap, 'placeRankingPrelim');

  let courseCodeFmt = '';
  if (courseCode === SDIF_COURSE_SCY) {
    courseCodeFmt = COURSE_SCY_JSON_OUTPUT;
  } else if (courseCode === SDIF_COURSE_LCM) {
    courseCodeFmt = COURSE_LCM_JSON_OUTPUT;
  } else if (courseCode === SDIF_COURSE_SCM) {
    courseCodeFmt = COURSE_SCM_JSON_OUTPUT;
  }

  return {
    courseCode: courseCodeFmt,
    swimTime: swimTime,
    heatNumber: heatNumber,
    laneNumber: laneNumber,
    placeRanking: placeRanking
  }
}

function getTimeComponents(line) {
  const seedTime = getVal(line, D0ColMap, 'seedTime').trim();
  const swimDate = getVal(line, D0ColMap, 'swimDate');
  const swimDateFormatted = `${swimDate.substring(0, 2)}/${swimDate.substring(2, 4)}/${swimDate.substring(4, 9)}`;
  const distance = getVal(line, D0ColMap, 'distance').trim();
  const strokes = [{ id: 1, code: 'FR' }, { id: 2, code: 'BK' }, { id: 3, code: 'BR' }, { id: 4, code: 'FL' }, { id: 5, code: 'IM' }, { id: 'H', code: 'H'}];
  const strokeId = getVal(line, D0ColMap, 'strokeId');

  const strokeIndex = strokeId === "H" ? 6 : strokeId;

  return {
    seedTime: seedTime,
    swimDate: swimDateFormatted,
    distance: distance,
    strokeCode: (strokes[strokeIndex - 1]) === undefined ? '' : strokes[strokeIndex - 1].code
  }
}

function hasNonAscii(line) {
  let badChar = false;
  [...line].forEach((charVal, i) => {
    const charCode = line.charCodeAt(i);
    if (charCode > 127) {
      badChar = true;
      return;
    }
  });

  return badChar;
}

function makePersonObject(line, timeType, errorArray, fileArrayIndex, memberIdObj) {
  if (hasNonAscii(line)) {
    logError(errorArray, ERROR_TYPE_DATA, 'Non-ASCII characters found and record was skipped', fileArrayIndex + 1);
    return {}
  }

  let name = timeType === TIME_TYPE_INDIVIDUAL ? getVal(line, D0ColMap, 'name') : getVal(line, F0ColMap, 'name');

  if (timeType === TIME_TYPE_RELAY) {
    memberIdObj.id = getVal(line, F0ColMap, 'memberId');
  }

  let lastName = getValSubstring(name, 0, name.indexOf(',')).trim();
  let firstAndMiddle = getValSubstring(name, name.indexOf(',') + 2, 28).trim();
  let firstName = "";
  let middleName = "";
  let firstAndMiddleNameArray = firstAndMiddle.split(' ');

  if (firstAndMiddleNameArray.length === 1) {
    firstName = firstAndMiddleNameArray[0];
  } else if (firstAndMiddleNameArray.length === 2) {
    firstName = firstAndMiddleNameArray[0];
    middleName = firstAndMiddleNameArray[1];
  } else if (firstAndMiddleNameArray.length === 3) {
    const thirdVal = firstAndMiddleNameArray[2];
    let thirdName = "";
    if (/^[0-9]*$/.test(thirdVal) === false) {
      thirdName = thirdVal;
    }
    firstName = `${firstAndMiddleNameArray[0]} ${firstAndMiddleNameArray[1]} ${thirdName}`;
    middleName = thirdName.length === 1 ? thirdName : "";
  } else if (firstAndMiddleNameArray.length === 4) {
    const forthVal = firstAndMiddleNameArray[3];
    let forthName = "";
    if (/^[0-9]*$/.test(forthVal) === false) {
      forthName = forthVal;
    }

    firstName = `${firstAndMiddleNameArray[0]} ${firstAndMiddleNameArray[1]} ${firstAndMiddleNameArray[2]} ${forthName}`;
    middleName = forthName.length === 1 ? forthName : "";
  }

  const dob = timeType === TIME_TYPE_INDIVIDUAL ? getVal(line, D0ColMap, 'birthDate') : getVal(line, F0ColMap, 'birthDate');
  let dobFormatted = `${dob.substring(0, 2)}/${dob.substring(2, 4)}/${dob.substring(4, 9)}`;
  let gender = timeType === TIME_TYPE_INDIVIDUAL ? getVal(line, D0ColMap, 'gender') : getVal(line, F0ColMap, 'gender');

  return {
    memberId: memberIdObj.id,
    firstName: firstName.trimEnd(),
    lastName: lastName,
    middleName: middleName,
    birthDate: dobFormatted === "//" ? "01/01/1900" : dobFormatted,
    gender: gender
  }
}

function makeTimeObj(timeComponents, timeAndCourse, session) {
  return {
    person: {},
    courseCode: timeAndCourse.courseCode,
    seedTime: timeComponents.seedTime,
    swimDate: timeComponents.swimDate,
    distance: timeComponents.distance,
    stroke: timeComponents.strokeCode,
    session: session,
    swimTime: timeAndCourse.swimTime,
    splits: [],
    heatNumber: timeAndCourse.heatNumber,
    laneNumber: timeAndCourse.laneNumber,
    placeRanking: timeAndCourse.placeRanking
  };
}

function makeSplitsArrayFromSplitsLine(splitsLine, splitLineCount) {
  const MAX_SPLITS_PER_LINE = 10;
  let splitsArray = [];

  //All splits have a decimal point
  const splitsCount = (splitsLine.match(/[.]/g) || []).length;

  //There will always be 1 split or an even number of splits, otherwise splits are missing so we don't get any of them
  //except for the 1650 that has 3 splits on split line number 4
  if ((splitsCount !== 1 && splitsCount % 2 !== 0) && !(splitsCount === 3 && splitLineCount === 4)) return [];

  let splitsLinePadded = "";

  if (splitsLine.indexOf('.') === 1) {
    splitsLinePadded = `    ${splitsLine}`;
  } else if (splitsLine.indexOf('.') === 2) {
    splitsLinePadded = `   ${splitsLine}`;
  } else if (splitsLine.indexOf('.') === 4) {
    splitsLinePadded = ` ${splitsLine}`;
  } else {
    splitsLinePadded = splitsLine;
  }

  for (let i = 0; i < MAX_SPLITS_PER_LINE; i++) {
    const split = getValSubstring(splitsLinePadded, G0SplitPosColMap[i].start, G0SplitPosColMap[i].end);
    splitsArray.push(split.trim());
  }

  return splitsArray;
}

function getSplitsArraysObject(fileArray, fileArrayIndex) {
  let splitArrayFinals = [];
  let splitArrayPrelims = [];
  let i;
  let splitLineCount = 0;

  for (i = fileArrayIndex; i < fileArray.length; i++) {
    if (fileArray[i].substring(0, 2) === SDIF_INDIVIDUAL_EVENT_RECORD ||
      fileArray[i].substring(0, 2) === SDIF_RELAY_EVENT_RECORD ||
      fileArray[i].substring(0, 2) === SDIF_RELAY_NAME_RECORD) {
      break;
    }

    if (fileArray[i].substring(0, 2) === SDIF_SPLITS_RECORD) {
      splitLineCount++;
      const splitsLine = getVal(fileArray[i], G0ColMap, 'splitsLine');
      const splitsSession = getVal(fileArray[i], G0ColMap, 'splitsSession');
      const splitsArray = makeSplitsArrayFromSplitsLine(splitsLine, splitLineCount);

      //if multiple split lines are used and the first split line is empty or has missing splits
      //we don't want to get the splits from the second line. All splits or no splits.
      if (splitsArray.length === 0) break;

      if (splitsSession === SESSION_FINAL) {
        splitArrayFinals.push(...splitsArray);
      } else if (splitsSession === SESSION_PRELIM) {
        splitArrayPrelims.push(...splitsArray);
      }
    }
  }

  //remove empty splits
  splitArrayPrelims = splitArrayPrelims.filter((item) => {
    return item !== "";
  });

  splitArrayFinals = splitArrayFinals.filter((item) => {
    return item !== "";
  });

  splitArrayFinals.forEach((val, i) => splitArrayFinals[i] = {
    splitNumber: i + 1,
    splitTime: val
  });

  splitArrayPrelims.forEach((val, i) => splitArrayPrelims[i] = {
    splitNumber: i + 1,
    splitTime: val
  });

  return { splitArrayFinals, splitArrayPrelims };
}

function addTimeObjectToIndividualTimesArray(
  fileArray,
  fileArrayIndex,
  fileLine,
  individualTimesArray,
  errorArray,
  clubObject,
  session,
  memberIdObj) {

  let timeObj = {};

  let splitsArraysObj = {}
  let timeComponents = getTimeComponents(fileLine);
  splitsArraysObj = getSplitsArraysObject(fileArray, fileArrayIndex);

  let personObj = {};

  const nextFileLine = fileArray[fileArrayIndex];

  if (fileLine.substring(0,2) === "D0" && nextFileLine.substring(0,2) === "D3") {
    memberIdObj.id = getMemberId(fileArray, fileArrayIndex - 1);
  }

  personObj = makePersonObject(fileLine, TIME_TYPE_INDIVIDUAL, errorArray, fileArrayIndex, memberIdObj);

  if (Object.keys(personObj).length > 0) {
    const personErrorFound = errorCheckPersonObject(personObj, fileArrayIndex, errorArray);
    let timeAndCourse = getTimeAndCourseBySession(fileLine, session);

    //Don't include times that contain codes like 'NS' instead of a valid time
    if ((timeAndCourse.swimTime.length > 0 && timeAndCourse.swimTime.indexOf('.') > 0) || 
        (session === TIMES_RECON && timeComponents.seedTime.length > 0 && timeComponents.seedTime.indexOf('.') > 0)) {
      timeObj = makeTimeObj(timeComponents, timeAndCourse, session);

      let splitsFinals = splitsArraysObj.splitArrayFinals;
      let splitsPrelims = splitsArraysObj.splitArrayPrelims;

      timeObj.person = personObj;
      timeObj.splits = timeObj.session === SESSION_FINAL ? splitsFinals : splitsPrelims;
      timeObj.club = clubObject;

      let timeObjectErrorFound = errorCheckTimeObject(timeObj, fileArrayIndex, errorArray, SESSION_FINAL_LABEL, TIME_TYPE_INDIVIDUAL);

      if (timeObj.courseCode.trim().length === 3 && !timeObjectErrorFound && !personErrorFound) {
        individualTimesArray.push(timeObj);
      }
    }
  }
}

const populateClubObjSwimmers = (line, clubObj, swimmerArray, errorArray, fileArrayIndex, memberIdObj) => {
  populateSwimmerArray(line, swimmerArray, errorArray, fileArrayIndex, memberIdObj);
  clubObj.swimmers = swimmerArray;

  return clubObj;
}

const populateSwimmerArray = (line, swimmerArray, errorArray, fileArrayIndex, memberIdObj) => {
  let timeType = line.substring(0, 2) === "D0" ? TIME_TYPE_INDIVIDUAL : TIME_TYPE_RELAY;
  let personObj = makePersonObject(line, timeType, errorArray, fileArrayIndex, memberIdObj);

  if (swimmerArray.find(x =>
    x.lastName === personObj.lastName &&
    x.firstName === personObj.firstName &&
    x.middleName === personObj.middleName &&
    x.birthDate === personObj.birthDate) === undefined &&
    Object.keys(personObj).length !== 0 && personObj.lastName.length > 0) {
    swimmerArray.push(personObj);
  }

  return swimmerArray;
}

const getMemberId = (fileArray, index) => {
  const fileLine = fileArray[index + 1];
  let memberId = "";
  if (fileLine.substring(0, 2) === "D3") {
    memberId = getVal(fileLine, D3ColMap, 'memberId');
  }

  return memberId;
}

const makeIndividualTimesArray = (line, individualTimesArray, fileArray, fileArrayIndex, clubObj, errorArray, memberIdObj, isTimesRecon = false) => {
  if (isTimesRecon === true) {
     //Times Recon
    addTimeObjectToIndividualTimesArray(fileArray, fileArrayIndex, line, individualTimesArray, errorArray, clubObj, TIMES_RECON, memberIdObj);
  } else {
    //Finals
    addTimeObjectToIndividualTimesArray(fileArray, fileArrayIndex, line, individualTimesArray, errorArray, clubObj, SESSION_FINAL, memberIdObj);

    //Prelims
    addTimeObjectToIndividualTimesArray(fileArray, fileArrayIndex, line, individualTimesArray, errorArray, clubObj, SESSION_PRELIM, memberIdObj);

    //SwimOff
    addTimeObjectToIndividualTimesArray(fileArray, fileArrayIndex, line, individualTimesArray, errorArray, clubObj, SESSION_SWIMOFF, memberIdObj);
  }
  
  return individualTimesArray;
}

const makeClubObject = (line) => {
  let swimmerArray = [];

  return {
    lscId: getVal(line, C1ColMap, 'lscId'),
    clubCode: getVal(line, C1ColMap, 'clubCode'),
    clubName: getVal(line, C1ColMap, 'clubName'),
    swimmers: swimmerArray
  }
}

const makeErrorsObject = (errorArray, meetId) => {
  return {
    sanctionId: meetId,
    errors: [errorArray]
  }
}

function convertTimeToSeconds(time) {
  const [mm = '0', ss = '00.00'] = time.split(':');
  const minute = parseInt(mm, 10) || 0;
  const second = parseFloat(ss, 10) || 0;

  return (minute * 60) + (second);
}

function convertSecondsToTime(seconds) {
  const dateObj = new Date(seconds * 1000);
  const hours = dateObj.getUTCHours();
  const minutes = dateObj.getUTCMinutes();
  const secondsPart = dateObj.getSeconds();
  const millisecondsPart = dateObj.getMilliseconds();

  const timeString = hours.toString().padStart(2, '0')
    + ':' + minutes.toString().padStart(2, '0')
    + ':' + secondsPart.toString().padStart(2, '0')
    + '.' + millisecondsPart;

  return timeString;
}

function setLegTimesFromSplits(legsArray) {
  if (
    legsArray[0].splits.length === 0 ||
    legsArray[1].splits.length === 0 ||
    legsArray[2].splits.length === 0 ||
    legsArray[3].splits.length === 0) return false;

  const leg1Time = legsArray[0].splits[legsArray[0].splits.length - 1].splitTime;
  const leg2Time = legsArray[1].splits[legsArray[1].splits.length - 1].splitTime;
  const leg3Time = legsArray[2].splits[legsArray[2].splits.length - 1].splitTime;
  const leg4Time = legsArray[3].splits[legsArray[3].splits.length - 1].splitTime;

  const leg1TimeFormatted = leg1Time.indexOf(':') > 0 ? leg1Time : '00:' + leg1Time;
  const leg1TimeFormattedWithHours = '00:' + leg1TimeFormatted + '0';
  const leg2TimeFormatted = leg2Time.indexOf(':') > 0 ? leg2Time : '00:' + leg2Time;

  const leg3TimeFormatted = leg3Time.indexOf(':') > 0 ? leg3Time : '00:' + leg3Time;

  legsArray[0].legTime = leg1TimeFormattedWithHours;

  legsArray[1].legTime = convertSecondsToTime(
    convertTimeToSeconds(leg2TimeFormatted) - convertTimeToSeconds(leg1TimeFormatted) //leg1Time.indexOf(':') > 0 ? ConvertTimeToSeconds(leg1Time) : leg1Time
  );

  legsArray[2].legTime = convertSecondsToTime(
    convertTimeToSeconds(leg3TimeFormatted) - convertTimeToSeconds(leg2TimeFormatted)
  );

  legsArray[3].legTime = convertSecondsToTime(
    convertTimeToSeconds(leg4Time) - convertTimeToSeconds(leg3TimeFormatted)
  );
}

function setPrelimLegTimesFromSplits(legsArray) {
  if (
    legsArray[4].splits.length === 0 ||
    legsArray[5].splits.length === 0 ||
    legsArray[6].splits.length === 0 ||
    legsArray[7].splits.length === 0) return false;

  const leg5Time = legsArray[4].splits[legsArray[4].splits.length - 1].splitTime;
  const leg6Time = legsArray[5].splits[legsArray[5].splits.length - 1].splitTime;
  const leg7Time = legsArray[6].splits[legsArray[6].splits.length - 1].splitTime;
  const leg8Time = legsArray[7].splits[legsArray[7].splits.length - 1].splitTime;

  const leg5TimeFormatted = leg5Time.indexOf(':') > 0 ? leg5Time : '00:' + leg5Time;
  const leg5TimeFormattedWithHours = '00:' + leg5TimeFormatted + '0';
  const leg6TimeFormatted = leg6Time.indexOf(':') > 0 ? leg6Time : '00:' + leg6Time;

  legsArray[4].legTime = leg5TimeFormattedWithHours;

  legsArray[5].legTime = convertSecondsToTime(
    convertTimeToSeconds(leg6TimeFormatted) - convertTimeToSeconds(leg5TimeFormatted) 
  );

  legsArray[6].legTime = convertSecondsToTime(
    convertTimeToSeconds(leg7Time) - convertTimeToSeconds(leg6TimeFormatted)
  );

  legsArray[7].legTime = convertSecondsToTime(
    convertTimeToSeconds(leg8Time) - convertTimeToSeconds(leg7Time)
  );
}

function addLegToLegsArray(F0Line, legsArray, fileArray, fileArrayIndex, session, errorArray, memberIdObj) {
  let personObj = {};
  personObj = makePersonObject(F0Line, TIME_TYPE_RELAY, errorArray, fileArrayIndex, memberIdObj);

  if (Object.keys(personObj).length > 0) {
    const relayPersonObj = {
      memberId: personObj.memberId,
      firstName: personObj.firstName,
      lastName: personObj.lastName,
      middleName: personObj.middleName,
      birthDate: personObj.birthDate,
      gender: personObj.gender
    };

    //get session from leg split
    const G0Rec = fileArray[fileArrayIndex + 1];
    const sessionFromSplit = getVal(G0Rec, G0ColMap, 'splitsSession') || "";

    //if split session doesn't exit use the passed in session
    session = sessionFromSplit.length > 0 ? sessionFromSplit : session;

    const legObject = {
      legNumber: session === SESSION_PRELIM ? getVal(F0Line, F0ColMap, 'legNumberPrelim') : getVal(F0Line, F0ColMap, 'legNumberFinal'),
      legTime: getVal(F0Line, F0ColMap, 'legTime'),
      person: relayPersonObj,
      splits: []
    };

    const splitsArrays = getSplitsArraysObject(fileArray, fileArrayIndex + 1);

    legObject.splits = session === SESSION_PRELIM ? splitsArrays.splitArrayPrelims : splitsArrays.splitArrayFinals;

    const relayLegPersonErrorFound = errorCheckPersonObject(relayPersonObj, fileArrayIndex + 1, errorArray);

    if (!relayLegPersonErrorFound) {
      legsArray.push(legObject);
    }

    if (legsArray.length === 4) {
      setLegTimesFromSplits(legsArray);
    } else if (legsArray.length === 8) {
      setPrelimLegTimesFromSplits(legsArray);
    }
  }
}

function getRelaySession(fileArray, fileArrayIndex, E0Line) {
  const FirstG0Line = fileArray[fileArrayIndex + 2];
  let session = "";

  if (FirstG0Line.substring(0, 2) === SDIF_SPLITS_RECORD) {
    session = getVal(FirstG0Line, G0ColMap, 'splitsSession');
  }

  //if we can't get session from the split record (does not exist), determine session by position of relay time
  if (session.length === 0) {
    if (getVal(E0Line, E0ColMap, 'timeFinal').length > 0) {
      session = SESSION_FINAL;
    } else {
      session = SESSION_PRELIM;
    }
  }

  return session;
}

function makeRelayObject(E0Line, clubObj, session, isTimesRecon) {
  let eventGender = getVal(E0Line, E0ColMap, 'eventGender');

  eventGender = eventGender === "X" ? "MX" : eventGender;
  
  let relayTime = session === SESSION_PRELIM ? getVal(E0Line, E0ColMap, 'timePrelim') : getVal(E0Line, E0ColMap, 'timeFinal');
  let relaySeedTime = getVal(E0Line, E0ColMap, 'seedTime');

  if (relayTime.indexOf('.') === -1 && isTimesRecon === false) return {};
  if (relaySeedTime.indexOf('.') === -1 && isTimesRecon === true) return {};

  const relayStrokeMap = {
    6: { code: 'FR-R' },
    7: { code: 'MED-R' }
  }

  const strokeId = getVal(E0Line, E0ColMap, 'strokeId');
  //skip relays that are not coded correctly
  if (strokeId < 6 || strokeId > 7 || strokeId === "H") return {};

  const courseCode = getVal(E0Line, E0ColMap, `courseCode${sessionCodeMap[session].value}`); 

  let courseCodeFmt = '';
  if (courseCode === SDIF_COURSE_SCY) {
    courseCodeFmt = COURSE_SCY_JSON_OUTPUT;
  } else if (courseCode === SDIF_COURSE_LCM) {
    courseCodeFmt = COURSE_LCM_JSON_OUTPUT;
  } else if (courseCode === SDIF_COURSE_SCM) {
    courseCodeFmt = COURSE_SCM_JSON_OUTPUT;
  }

  const swimDate = getVal(E0Line, E0ColMap, 'swimDate');
  const swimDateFormatted = `${swimDate.substring(0, 2)}/${swimDate.substring(2, 4)}/${swimDate.substring(4, 9)}`;

  const relayObj = {
    eventGender: eventGender,
    session: session,
    seedTime: relaySeedTime,
    relayTime: relayTime,
    distance: getVal(E0Line, E0ColMap, 'distance'),
    swimDate: swimDateFormatted,
    stroke: relayStrokeMap[strokeId].code,
    courseCode: courseCodeFmt,
    heatNumber: session === SESSION_PRELIM ? getVal(E0Line, E0ColMap, 'heatNumberPrelim') : getVal(E0Line, E0ColMap, 'heatNumberFinal'),
    laneNumber: session === SESSION_PRELIM ? getVal(E0Line, E0ColMap, 'laneNumberPrelim') : getVal(E0Line, E0ColMap, 'laneNumberFinal'),
    placeRanking: session === SESSION_PRELIM ? getVal(E0Line, E0ColMap, 'placeRankingPrelim') : getVal(E0Line, E0ColMap, 'placeRankingFinal'),
    team: clubObj,
    legs: []
  }

  return relayObj;
}

function makeLegsArray(fileArray, fileArrayIndex, session, errorArray, memberIdObj) {
  let legsArray = [];
  fileArrayIndex++;

  for (let i = fileArrayIndex; i < fileArray.length; i++) {
    let recCode = fileArray[i].substring(0, 2);
    if (recCode === SDIF_INDIVIDUAL_EVENT_RECORD || recCode === SDIF_RELAY_EVENT_RECORD || recCode === SDIF_TEAM_ID_RECORD) {
      break;
    }

    switch (recCode) {
      case SDIF_RELAY_NAME_RECORD:
        addLegToLegsArray(fileArray[i], legsArray, fileArray, i, session, errorArray, memberIdObj);
        break;
      case SDIF_SPLITS_RECORD:
        break;
      default:
        break;
    }
  }

  return legsArray;
}

const makeRelayArray = (relayArray, fileArray, fileArrayIndex, clubObj, errorArray, memberIdObj, isTimesRecon = false) => {
  const E0Line = fileArray[fileArrayIndex];
  
  let session = isTimesRecon === true ? "R" : getRelaySession(fileArray, fileArrayIndex, E0Line);
  let relayObj = makeRelayObject(E0Line, clubObj, session, isTimesRecon);
  let legsArray = makeLegsArray(fileArray, fileArrayIndex, session, errorArray, memberIdObj);

  if (legsArray.length === 4) {
    if (Object.keys(relayObj).length > 0) {
      relayObj.legs = legsArray;
      relayArray.push(relayObj);
    } else {
      logError(errorArray, ERROR_TYPE_STRUCTURE, 'Invalid relay time or relay structure', fileArrayIndex + 1);
    }
  } 
  
  if (legsArray.length > 4) {
    const arrLen = legsArray.length;

    //are the 1st 4 legs complete?
    if (legsArray[0].legNumber === "1"  && legsArray[1].legNumber === "2" && legsArray[2].legNumber === "3" && legsArray[3].legNumber === "4")  {
      if (Object.keys(relayObj).length > 0) {
        relayObj.legs = legsArray.slice(0,4);
        relayArray.push(relayObj);
      } else {
        logError(errorArray, ERROR_TYPE_STRUCTURE, 'Invalid relay time or relay structure', fileArrayIndex + 1);
      }
    }  
    
    //are the last 4 legs complete?
    if (legsArray[arrLen - 4].legNumber === "1"  && legsArray[arrLen - 3].legNumber === "2" && legsArray[arrLen - 2].legNumber === "3" && legsArray[arrLen - 1].legNumber === "4") {
      const relayPrelimObj = makeRelayObject(E0Line, clubObj, "P", isTimesRecon);
      if (Object.keys(relayPrelimObj).length > 0) {
        relayPrelimObj.legs = legsArray.slice(4,8);
        relayArray.push(relayPrelimObj);
      } else {
        logError(errorArray, ERROR_TYPE_STRUCTURE, 'Invalid relay time or relay structure', fileArrayIndex + 1);
      }
    }
  }
}

const SDIFConversion = {
  makeClubObject,
  makeRelayArray,
  makeIndividualTimesArray,
  populateClubObjSwimmers,
  populateSwimmerArray,
  checkFileStructure,
  makeErrorsObject,
  getMemberId
};

export default SDIFConversion;