import React, { Fragment } from 'react';

import TimesValidationLargeGrid from './TimesValidationLargeGrid';
import TimesValidationSmallGrid from './TimesValidationSmallGrid';

const TimesValidationGrid = ({ gridData, isLoading, onEditTime }) => {
  return (
    <Fragment>
      <TimesValidationLargeGrid
        gridData={gridData}
        isLoading={isLoading}
        onEditTime={onEditTime}
      />
      <TimesValidationSmallGrid
        gridData={gridData}
        isLoading={isLoading}
        onEditTime={onEditTime}
      />
    </Fragment>
  )
};

export default TimesValidationGrid;